import * as React from "react";
import Layout from "../components/Layout";

const NotFoundPage = () => (
  <Layout>
    <div className="section is-large my-6 py-6" style={{ position: 'relative' }}>
      <div className="block has-text-centered">
        <h1 className="title is-h1">Not Found.</h1>
        <h4 className="is-h4 is-offset-1">Nothing to see here. Sorry!</h4>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
